import React from "react"
import { rhythm } from "src/utils/typography"
import { animateScroll as scroll } from 'react-scroll'
import { FaCaretSquareUp } from 'react-icons/fa'
import logo from 'src/images/logo.jpg'
import AniLink from "gatsby-plugin-transition-link/AniLink";

const TemplateLayout = ({ destination, mainImage, children }) => {
  const scrollToTop = () => { scroll.scrollToTop() }
  let header = (
    <h3
      style={{
        fontFamily: `Montserrat, sans-serif`,
        marginTop: 0,
      }}
    >
      <AniLink fade
        style={{
          boxShadow: `none`,
          color: `inherit`,
        }}
        to={destination}
      >
        <img src={logo} alt="logo" width="186.656" height="32" />
      </AniLink>
    </h3>
  )

  return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }} >
        <header>{header}</header>
        <main style={{minHeight: "calc(100vh - 12rem)"}}>{children}</main>
        <footer>
        Copyright &copy; Sherif Sabri, {new Date().getFullYear()} &nbsp;
          <a href="#Top" aria-label="scroll to top" onClick={scrollToTop}><FaCaretSquareUp /></a>
        </footer>
      </div>
  )
}

export default TemplateLayout
